import request from "@/utils/request";

export function getPredefinedDownload(id) {
  return request({
    url: "api/datafeed/urllists/predefined/download_txt/" + id,
    method: "get",
  });
}

export function getCustomDownload(id) {
  return request({
    url: "api/datafeed/urllists/custom/download_txt/" + id,
    method: "get",
  });
}
