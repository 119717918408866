<template>
  <v-container :fluid="true">
    <v-row>
      <v-col cols="12">
        <v-btn v-if="file !== ''" color="primary" class="text-capitalize">
          Download
        </v-btn>
        <pre v-if="file !== ''">
          {{ file }}
        </pre>
      </v-col>
    </v-row>
    <v-row v-if="loading" class="mt-0 mx-0">
      <v-col cols="12">
        <v-icon color="primary">fas fa-spinner fa-spin</v-icon>
        <span class="font-roboto-condensed-light font-size-15 ml-2"
          >Loading...</span
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { Errors } from "@/utils/error";
import { getPredefinedDownload } from "@/api/datafeeds/download";

export default {
  data() {
    return {
      file: "",
      loading: true,
    };
  },
  created() {
    this.loading = true;
    getPredefinedDownload(1)
      .then((response) => {
        this.file = response;
        this.loading = false;
      })
      .catch((e) => {
        Errors.show(e);
        this.loading = false;
      });
  },
};
</script>

<style lang="scss" scoped>
.v-btn {
  border-radius: 15px;
  position: fixed;
  right: 10px;
}
</style>
